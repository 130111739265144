/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Custom Modal Styles */
.product-detail-modal,
.service-detail-modal {
  --width: 90%;
  --max-width: 1000px;
  --min-width: 800px;
  --height: 90%;
  --border-radius: 20px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

/* Responsive adjustments for small screens */
@media (max-width: 800px) {
  .product-detail-modal,
  .service-detail-modal {
    --width: 95%;
    --min-width: 95%;
    --height: 95%;
  }
}

/* Racing fonts styling */
:root {
  --ion-font-family: 'Russo One', sans-serif;
}

/* Preloader Styles */
#app-preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #121217 0%, #1e1e2f 100%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.5s ease;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%233880ff' fill-opacity='0.05'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.2;
  }
  
  &.loaded {
    opacity: 0;
  }
  
  .preloader-content {
    text-align: center;
    z-index: 1;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
  }
  
  .logo-container {
    margin-bottom: 40px;
    
    .preloader-logo {
      width: 140px;
      height: 140px;
      animation: float 3s infinite ease-in-out;
      background-color: white;
      border-radius: 50%;
      padding: 8px;
      box-shadow: 0 10px 30px rgba(56, 128, 255, 0.3);
    }
  }
  
  .loading-animation {
    width: 200px;
    height: 4px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 6px;
    margin: 0 auto 60px;
    overflow: hidden;
    position: relative;
    
    .loading-bar {
      position: absolute;
      top: 0;
      left: -100%;
      height: 100%;
      width: 50%;
      background: linear-gradient(90deg, 
        transparent,
        var(--ion-color-primary), 
        var(--ion-color-secondary),
        transparent
      );
      border-radius: 6px;
      animation: loading-slide 2s infinite ease;
    }
  }
  
  .welcome-message {
    color: white;
    
    h2 {
      font-size: 26px;
      margin-bottom: 15px;
      background: linear-gradient(90deg, var(--ion-color-primary), var(--ion-color-secondary));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      letter-spacing: 1px;
    }
    
    p {
      font-size: 15px;
      margin: 0;
      opacity: 0.7;
      letter-spacing: 0.5px;
      line-height: 1.4;
    }
  }
}

@keyframes loading-slide {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
    box-shadow: 0 10px 30px rgba(56, 128, 255, 0.3);
  }
  50% {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(56, 128, 255, 0.4);
  }
  100% {
    transform: translateY(0px);
    box-shadow: 0 10px 30px rgba(56, 128, 255, 0.3);
  }
}

body {
  font-family: 'Russo One', sans-serif;
  letter-spacing: 0.5px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Russo One', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.racing-text {
  font-family: 'Russo One', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 1.2;
  
  &.slant {
    transform: skewX(-10deg);
    display: inline-block;
  }
  
  &.gradient {
    background: linear-gradient(90deg, var(--ion-color-primary), var(--ion-color-secondary));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    
    /* For better visibility on darker backgrounds */
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
  }
  
  /* Optional racing-style border effect for items */
  &.bordered {
    position: relative;
    z-index: 1;
    
    &:before {
      content: '';
      position: absolute;
      top: -3px;
      left: -8px;
      right: -3px;
      bottom: -3px;
      background: transparent;
      border: 2px solid var(--ion-color-secondary);
      transform: skewX(-15deg);
      z-index: -1;
    }
  }
}
